import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "./SiteLayout"
import PageSEO from "./PageSEO"

export default function GenericMarkdownTemplate({ data }) {
  const page = data.markdownRemark
  return (
    <SiteLayout>
      <PageSEO title={page.frontmatter.title} />
      <div>
        <h2>{page.frontmatter.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
      </div>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
